import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button";

const Page = () => {
  return (
      <LessonLayout
        current_step={8}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 8</H2>

          <p>Take a few minutes to assign an overall score for each of the 3 websites that you reviewed. The scoring guidelines are at the end of the rubric.</p>
          <p>If you want to look at them again, click the buttons below.</p>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/safe-environment"
              icon="external"
              title="Article Lesson 1"
            >
              Website 1
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/active-life"
              icon="external"
              title="Article Lesson 1"
            >
              Website 2
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/lung-health"
              icon="external"
              title="Article Lesson 1"
            >
              Website 3
            </Button>
          </div>

          <p>Remember, you are just thinking about the "Website Quality Initial Screen" category of the rubric. For each website, you will have 1 score for this section of your rubric.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
